import React from 'react'
import  "./home.scss"
import Hero from './Hero/Hero';
import Projects from './Projects/Projects';
import Contact from './Contact/Contact';
const Home = () => {

  
  return (
    <>
      <Hero></Hero>
      <Projects></Projects>
      <Contact></Contact>
  
      </>
  )
}

export default Home
